
import { defineComponent, PropType, watch, ref, onMounted } from "vue";
import ImageLightBoxModal from "@/components/ThemeLesson/ImageLightBoxModal.vue";
import mixins from "@/mixins/index";
import axios from "axios";
import { Modal } from "bootstrap";
import { claps } from "@/api/index";
import autosize from "autosize";

// swiper引用
import Swiper, {
  Autoplay,
  EffectCoverflow,
  EffectCube,
  Pagination,
  Navigation,
} from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.scss";
Swiper.use([Autoplay, EffectCoverflow, EffectCube, Pagination, Navigation]);

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

export default defineComponent({
  components: {
    ImageLightBoxModal,
  },
  data() {
    return {
      spinnerStatus: true,
      clickstatus: false,
      messageCount: 1,
      moreOptions: false,
      imageLightBoxModal: null as any,
      swiper: null as any,
      count: 1 as number,
      isPosting: false,
      previousIndex: 0,
      activeIndex: 0,
      messageLengthBuffer: 0,
      replyLengthBuffer: 0,
      showMoreReplyClick: new Array<boolean>(),
      toastText: "",
      clapsStatus: false,
    };
  },
  props: ["userPhoto", "info", "index"],
  emits: [
    "updatePostLikeStatus",
    "updatePostMessage",
    "updateFollowTeacher",
    "updateComponent",
    "updateFollowingPost",
    "getMessage",
    "getMoreMessage",
    "sendPostId",
    "deleteMessage",
    "deleteReply",
    "editMessage",
    "editReply",
    "copyPostLink",
    "updateClaps"
  ],
  mixins: [mixins],
  methods: {
    showToast(obj: { info: string }) {
      // console.log("toast");
      this.toastText = obj.info;
      (this as any).$refs.Toast.showToast();
    },
    goToTeacherIntroduction(obj: { teacherId: number }) {
      this.$router.push(
        `/teachers/teachersintroduction?id=${obj.teacherId}&view=1`
      );
    },
    // 跟畫面返回按鍵一起禁用
    // goBack() {
    //   window.history.back();
    // },
    copyPostLink(obj: { postId: number }) {
      let env =
        process.env.VUE_APP_ENV === "production"
          ? "https://ourscool.com.tw/"
          : "https://talc-dev.ourscool.com.tw/";
      const input = document.createElement("input");
      input.style.opacity = "0";
      input.style.position = "absolute";
      input.style.pointerEvents = "none";
      input.value = `${env}specific-post?id=${obj.postId}`;
      document.body.appendChild(input);
      input.select();
      const success = document.execCommand("copy");
      document.body.removeChild(input);
      if (success) {
        this.$emit("copyPostLink", { info: "已複製連結" });
      } else {
        this.$emit("copyPostLink", { info: "複製連結失敗" });
      }
    },
    subComment() {
      let targets = document.querySelectorAll(".sub-comment-text");
    },
    autosizeInit() {
      autosize(document.querySelectorAll("textarea"));
    },
    updateAutosize() {
      let targets = document.querySelectorAll("textarea");
      for (let i = 0; i < targets.length; i++) {
        autosize.update(targets[i]);
        let target = targets[i];
        let buffer = target.value;
        target.value = target.value + "";
        target.value = buffer;
      }
    },
    showMoreOptions() {
      this.moreOptions = true;
      // 更多選項的選單
    },
    closeMoreOptions() {
      this.moreOptions = false;
    },
    // 取得該筆貼文留言，發到父階 call API
    getMessage() {
      this.$emit("getMessage", {
        id: this.info.id,
        index: this.info.index,
        posterId: this.info.user_id,
      });
      // setTimeout(this.messageShowMoreOptions, 1000);
      // setTimeout(this.replyShowMoreOptions, 1000);
    },
    getMoreMessage() {
      this.messageCount = this.messageCount + 1;
      this.$emit("getMoreMessage", {
        id: this.info.id,
        index: this.info.index,
        page: this.messageCount,
        posterId: this.info.user_id,
      });
      // setTimeout(this.messageShowMoreOptions, 1000);
      // setTimeout(this.replyShowMoreOptions, 1000);
    },
    messageShowMoreOptions(obj: { id: number; index: number }) {
      this.autosizeInit();
      let target = document.getElementById(
        `message-options-container-${obj.id}-${obj.index}`
      );
      target?.classList.remove("hide");
    },
    messageHideMoreOptions() {
      document.addEventListener("click", (event) => {
        const clickedElement = event.target as HTMLElement;
        const messageOptionsContainer = clickedElement.closest(
          ".message-options-container"
        );
        // if (!messageOptionsContainer) {
        const allMessageOptionsContainers = document.querySelectorAll(
          ".message-options-container"
        );
        allMessageOptionsContainers.forEach((container) => {
          container.classList.add("hide");
        });
        // }
      });
    },
    replyShowMoreOptions(obj: { id: number; index: number }) {
      this.autosizeInit();
      let target = document.getElementById(
        `reply-options-container-${obj.id}-${obj.index}`
      );
      target?.classList.remove("hide");
    },
    // 目前跟messageHideMoreOptions()共用，因此不必二次觸發
    replyHideMoreOptions() {
      document.addEventListener("click", (event) => {
        const clickedElement = event.target as HTMLElement;
        const messageOptionsContainer = clickedElement.closest(
          ".message-options-container"
        );
        // if (!messageOptionsContainer) {
        const allMessageOptionsContainers = document.querySelectorAll(
          ".message-options-container"
        );
        allMessageOptionsContainers.forEach((container) => {
          container.classList.add("hide");
        });
        // }
      });
    },
    deleteMessage(obj: {
      postIndex: number;
      postId: number;
      messageIndex: number;
      messageId: number;
    }) {
      this.$emit("deleteMessage", obj);
      axios
        .delete(`${serverUrl}thematic_learning/comment/${obj.messageId}`, {
          headers: {
            Authorization: loginToken,
          },
        })
        .then((res) => {
          // console.log(res);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    deleteReply(obj: {
      postIndex: number;
      postId: number;
      messageIndex: number;
      messageId: number;
      replyId: number;
      replyIndex: number;
    }) {
      this.$emit("deleteReply", obj);
      axios
        .delete(`${serverUrl}thematic_learning/comment/${obj.replyId}`, {
          headers: {
            Authorization: loginToken,
          },
        })
        .then((res) => {
          // console.log(res);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // 修改貼文留言邏輯說明
    // 按下送出留言後，對 API 發送請求
    // 同時將資料傳遞到父階，對該筆留言進行修改，這樣才可以即時顯示在當前畫面上
    // 並且關閉編輯的頁面
    editMessage(obj: {
      postIndex: number;
      postId: number;
      messageIndex: number;
      messageId: number;
    }) {
      let screenWidth = window.innerWidth;
      let target: HTMLInputElement;
      if (screenWidth >= 1024) {
        target = document.getElementById(
          `edit-message-comment-${obj.messageId}`
        )! as HTMLInputElement;
      } else {
        target = document.getElementById(
          `edit-message-comment-mobile-${obj.messageId}`
        )! as HTMLInputElement;
      }

      axios
        .patch(
          `${serverUrl}thematic_learning/${obj.messageId}`,
          {
            content: target.value,
          },
          {
            headers: {
              Authorization: loginToken,
            },
          }
        )
        .then((res) => {
          // console.log(res);
          this.editMessageHide({ id: obj.messageId });
          this.$emit("editMessage", obj, target.value);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    editReply(obj: {
      postIndex: number;
      postId: number;
      messageIndex: number;
      messageId: number;
      replyId: number;
      replyIndex: number;
    }) {
      let screenWidth = window.innerWidth;
      let target: HTMLInputElement;
      if (screenWidth >= 1024) {
        target = document.getElementById(
          `edit-reply-comment-${obj.replyId}`
        )! as HTMLInputElement;
      } else {
        target = document.getElementById(
          `edit-reply-comment-mobile-${obj.replyId}`
        )! as HTMLInputElement;
      }

      axios
        .patch(
          `${serverUrl}thematic_learning/${obj.replyId}`,
          {
            content: target.value,
          },
          {
            headers: {
              Authorization: loginToken,
            },
          }
        )
        .then((res) => {
          // console.log(res);
          this.editReplyHide({ id: obj.replyId });
          this.$emit("editReply", obj, target.value);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // 按下留言更多選項內的編輯留言，顯示編輯輸入框，隱藏原本的顯示內容
    editMessageShow(obj: { id: number }) {
      let container = document.getElementById(
        `message-comment-${obj.id}-container`
      );
      let editContainer = document.getElementById(
        `edit-message-comment-${obj.id}-container`
      );
      container?.classList.add("hide");
      editContainer?.classList.remove("hide");
      this.updateAutosize();
    },
    // 按下編輯留言輸入框的取消，取消編輯，回到顯示留言
    editMessageHide(obj: { id: number }) {
      this.autosizeInit();
      let container = document.getElementById(
        `message-comment-${obj.id}-container`
      );
      let editContainer = document.getElementById(
        `edit-message-comment-${obj.id}-container`
      );
      container?.classList.remove("hide");
      editContainer?.classList.add("hide");
    },
    // 按下留言更多選項內的編輯留言，顯示編輯輸入框，隱藏原本的顯示內容
    editReplyShow(obj: { id: number }) {
      let container = document.getElementById(
        `reply-comment-${obj.id}-container`
      );
      let editContainer = document.getElementById(
        `edit-reply-comment-${obj.id}-container`
      );
      container?.classList.add("hide");
      editContainer?.classList.remove("hide");
      this.updateAutosize();
    },
    // 按下編輯留言輸入框的取消，取消編輯，回到顯示留言
    editReplyHide(obj: { id: number }) {
      let container = document.getElementById(
        `reply-comment-${obj.id}-container`
      );
      let editContainer = document.getElementById(
        `edit-reply-comment-${obj.id}-container`
      );
      container?.classList.remove("hide");
      editContainer?.classList.add("hide");
    },
    showMessageReply(id: number, index: number) {
      let targetGroup = document.getElementsByClassName(
        `second-message-${id}-${index}`
      );
      for (let i = 0; i < targetGroup.length; i++) {
        targetGroup[i].classList.remove("hide");
      }

      let showMoreReplyBtn = document.getElementsByClassName(
        `show-more-reply-${id}-${index}`
      );
      for (let i = 0; i < showMoreReplyBtn.length; i++) {
        showMoreReplyBtn[i].classList.add("hide");
      }
    },
    showImageModal() {
      this.imageLightBoxModal.show();
    },
    closeImageLightBoxModal() {
      this.imageLightBoxModal.hide();
    },
    countCal(status: boolean) {
      if (status) {
        this.count = this.count + 1;
        // console.log(this.count);
      } else {
        this.count = this.count - 1;
        // console.log(this.count);
      }
    },
    sendPostId(obj: { id: number; index: number }) {
      this.$emit("sendPostId", obj);
    },
    // 處理發文中連結
    linkify(text: string) {
      // eslint-disable-next-line
      var urlRegex =
        /* eslint-disable no-useless-escape */
        /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
      return text.replace(urlRegex, function (url) {
        return (
          '<a class="text-primary-default" target="_blank" href="' +
          url +
          '">' +
          url +
          "</a>"
        );
      });
    },
    // 按讚貼文
    // postLike(postId: number, status: boolean) {
    postLike(obj: {
      postId: number;
      status: boolean;
      postIndex?: number;
      messageIndex?: number;
      replyIndex?: number;
    }) {
      // console.log(`${postId}按下讚${status}`);
      // console.log(obj)
      // 發 API
      axios
        .patch(
          `${serverUrl}thematic_learning/${obj.postId}/like`,
          {
            like: !obj.status,
          },
          {
            headers: {
              Authorization: loginToken,
            },
          }
        )
        .then((res) => {
          // console.log(res);
          this.$emit("updatePostLikeStatus", obj);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // 顯示回覆 Input
    showReplyButton(targetId: number) {
      const target = document.getElementById(
        `show-reply-comment-${targetId}`
      )! as HTMLElement;
      const targetTextArea = document.getElementById(
        `reply-comment-mobile-${targetId}`
      )! as HTMLTextAreaElement;
      target.style.display = "flex";
      targetTextArea.focus();
      this.autosizeInit();
      this.updateAutosize();
    },
    // 回覆貼文
    leaveComment(obj: { postId: number; postIndex: number }) {
      let screenWidth = window.innerWidth;
      let target = null;
      if (screenWidth >= 1024) {
        target = document.getElementById(
          `reply-post-${obj.postId}`
        )! as HTMLInputElement;
      } else {
        target = document.getElementById(
          `reply-post-mobile-${obj.postId}`
        )! as HTMLInputElement;
      }

      // console.log(obj.postId);
      // console.log(target);
      const message = target.value;
      if (message.trim() != "") {
        axios
          .post(
            `${serverUrl}thematic_learning/${obj.postId}/comment`,
            {
              content: message,
              position: 1,
            },
            {
              headers: {
                Authorization: loginToken,
              },
            }
          )
          .then((res) => {
            // res.data.data是將留言發送出去後，由 API 接收到的完整內容，包含用戶名字、該貼文 ID 等 ..
            // 必須將這裡完整的資料推入目前的結構，這樣才可以接續對該筆發出的留言進行按讚或回覆
            this.$emit("updatePostMessage", {
              postId: obj.postId,
              postIndex: obj.postIndex,
              message: res.data.data,
            });
          })
          .catch((error) => {
            console.error(error);
          });

        target.value = "";
      }
      this.updateAutosize();
    },
    // 回覆貼文留言
    leaveComment2(obj: {
      replyId: number;
      postIndex: number;
      messageIndex: number;
      replyIndex: number;
    }) {
      let screenWidth = window.innerWidth;
      let target = null;
      if (screenWidth >= 1024) {
        target = document.getElementById(
          `reply-comment-${obj.replyId}`
        )! as HTMLInputElement;
      } else {
        target = document.getElementById(
          `reply-comment-mobile-${obj.replyId}`
        )! as HTMLInputElement;
      }
      const message = target.value;
      if (message.trim() != "") {
        axios
          .post(
            `${serverUrl}thematic_learning/${obj.replyId}/comment`,
            {
              content: message,
              position: 2,
            },
            {
              headers: {
                Authorization: loginToken,
              },
            }
          )
          .then((res) => {
            let buffer = {
              cnName: res.data.data.cnName,
              content: res.data.data.content,
              createdAt: 999999999999999999999999999 * 1000,
              id: res.data.data.id,
              images: null,
              like: res.data.data.like,
              photo: res.data.data.photo,
              reply_to: null,
              role: res.data.data.role,
              user_id: res.data.data.user_id,
              nickname: res.data.data.nickname,
            };
            this.$emit("updatePostMessage", {
              replyId: obj.replyId,
              postIndex: obj.postIndex,
              messageIndex: obj.messageIndex,
              message: buffer,
            });
            const target = document.getElementById(
              `show-reply-comment-${obj.replyId}`
            )! as HTMLElement;
            target.style.display = "none";
          })
          .catch((error) => {
            console.error(error);
          });

        target.value = "";
      }
      this.updateAutosize();
    },
    moblieLeaveComment(obj: { postId: number; postIndex: number }, event: any) {
      if (event.inputType === "insertLineBreak") {
        event.preventDefault();
        // if (!event.shiftKey) {
        //   this.leaveComment(obj);
        // }
      }
    },
    // 追蹤老師
    followTeacher(obj: {
      id: number;
      followStatus: boolean;
      postIndex: number;
    }) {
      axios
        .post(
          `${serverUrl}teainfo/${obj.id}/follow`,
          {
            id: obj.id,
          },
          {
            headers: {
              Authorization: loginToken,
            },
          }
        )
        .then((res) => {
          // console.log(res);
          this.$emit("updateFollowTeacher", {
            teacherId: obj.id,
            status: true,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // 取消追蹤老師
    unfollowTeacher(obj: {
      id: number;
      followStatus: boolean;
      postIndex: number;
    }) {
      axios
        .delete(`${serverUrl}teainfo/${obj.id}/follow`, {
          headers: {
            Authorization: loginToken,
          },
        })
        .then((res) => {
          // console.log(res);
          this.$emit("updateFollowTeacher", {
            teacherId: obj.id,
            status: false,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // 追蹤 & 取消追蹤貼文
    updateFollowPost(obj: { id: number; status: boolean; postIndex: number }) {
      this.$emit("updateFollowingPost", obj);
      if (obj.status === false) {
        axios
          .patch(
            `${serverUrl}thematic_learning/post/${obj.id}/collect`,
            {
              follow: false,
            },
            {
              headers: {
                Authorization: loginToken,
              },
            }
          )
          .then((res) => {
            // console.log(res);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    showMorePostContent() {
      let target = document.getElementsByClassName(
        `post-content-hashtags-container-${this.info.index}`
      );
      target[0].classList.add("expanded");
      this.seeMore({ postId: this.info.id });
    },
    // 按下See more 需要發送 API 用以統計點擊數
    seeMore(obj: { postId: number }) {
      axios
        .patch(
          `${serverUrl}thematic_learning/${obj.postId}/seeMore`,
          {
            id: obj.postId,
          },
          {
            headers: {
              Authorization: loginToken,
            },
          }
        )
        .then((res) => {
          // console.log(res);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    claps(obj: { status: boolean; id: number; index: number }) {
      this.clapsStatus = obj.status;
      if (obj.status) {
        claps(obj.id);
        this.$emit("updateClaps", { index: obj.index });
      }
    },
  },
  // watch: {
  //   info() {
  //     console.log(this.info);
  //   },
  // },
  mounted() {
    this.autosizeInit();
    this.messageHideMoreOptions();
    // this.getMessage();
    this.showMorePostContent();
    // this.hideMessageOptions;
    document.body.addEventListener("click", (event: MouseEvent) => {
      this.closeMoreOptions();
    });
    if (this.info.images) {
      // 這邊要注意綁定的DOM不可以重複，因此在建立滑動區域時會加入id產生不同的物件
      this.swiper = new Swiper(`.swiper-${this.info.id}`, {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          hideOnClick: false,
        },
        grabCursor: true,
        on: {
          slideChange: function () {
            // 當滑動時觸發
            // 特別注意this需要被宣告型別，否則會一直報錯
            const previousIndex = (this as any).previousIndex as number;
            const activeIndex = (this as any).activeIndex as number;
            const currentPage = (this as any).activeIndex + 1;
            countCal(currentPage);
          },
        },
      });
    }
    let countCal = (currentPage: number) => {
      this.count = currentPage;
    };

    // 初始化 Modal
    this.imageLightBoxModal = new Modal(
      document.getElementById(
        `image-lightBox-modal-${this.info.id}`
      )! as HTMLElement
    );
  },
  beforeUnmount() {
    this.imageLightBoxModal = null;
    this.swiper = null;
  },
});
