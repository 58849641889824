
import { defineComponent } from "vue";
import Template517 from "@/views/templates/Template517.vue";
import CourseContentSpecificPost from "@/components/ThemeLesson/CourseContentSpecificPost.vue";

export default defineComponent({
  components: {
    Template517,
    CourseContentSpecificPost,
  },

  data() {
    return {};
  },
  methods: {},
});
