import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CourseContentSpecificPost = _resolveComponent("CourseContentSpecificPost")!
  const _component_Template517 = _resolveComponent("Template517")!

  return (_openBlock(), _createBlock(_component_Template517, null, {
    "mid-column": _withCtx(() => [
      _createVNode(_component_CourseContentSpecificPost)
    ]),
    _: 1
  }))
}