
import { defineComponent } from "vue";

export default defineComponent({
  name: "Template517",
  data() {
    return {
      screenWidth: 0,
    };
  },
  methods: {},
});
