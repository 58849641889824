
import { defineComponent, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import mixins from "@/mixins/index";
import axios from "axios";
import { Modal, Popover } from "bootstrap";
import { Post } from "@/types/models";
import { Offcanvas } from "bootstrap";
import { singlePostGet } from "@/api/index";
import CourseContentCardSpecificPost from "@/components/ThemeLesson/CourseContentCardSpecificPost.vue";
import AddToFavoriteClassificationModal from "@/components/ThemeLesson/AddToFavoriteClassificationModal.vue";
import Toast from "@/components/Toast/Toast.vue";
const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

interface PostUpdateParams {
  id?: number;
  page?: number;
  displayedNum?: number;
  teacherId?: number;
  category?: string;
}

export default defineComponent({
  components: {
    CourseContentCardSpecificPost,
    AddToFavoriteClassificationModal,
    Toast,
  },
  data() {
    return {
      toastText: "",
      routePath: "",
      // 用於判斷是否是顯示獨立貼文
      postId: -1,
      value: "",
      // Modal
      addToFavoriteClassification: null as any,
      followingKey: 0,
      updateKey: 0,
      spinnerStatus: true,
      spinnerStatusCount: 0,
      lodaMoreMessage: false,
      clickstatus: false,
      slideCanvasSwiperBind: false,
      // 目前頁面所有的貼文
      tercherPostTesting: [] as Array<Post>,
      postInfo: [
        {
          cnName: "黃柔恩",
          collect: false,
          commentCount: 3,
          content: "你好\n你好\n你好\n你好\n你好\n你好\n你好",
          file: null,
          follow: false,
          foreignName: "Irene",
          id: 182,
          images: [],
          imagesUrl: [
            "https://talcapi-dev.ourscool.com.tw/api/download?s…77652364-a59f693f-6dac-4a42-9e26-31814e3ecfd3.png",
            "https://talcapi-dev.ourscool.com.tw/api/download?s…77670628-79b66b3f-89f4-4794-96c2-ffe2293ca7ae.png",
          ],
          index: 0,
          like: true,
          likeCount: 0,
          photo:
            "https://talcapi-dev.ourscool.com.tw/api/download?section=teaInfo&f=1657010646-9f4bc30c-071d-4726-86eb-c82303a1ee0e.jpg",
          post: [],
          postTime: 1677652365,
          role: "T",
          tags: ["#我好", "#他好"],
          user_id: 47,
        },
      ] as Array<any>,
      postPageCount: 1,
      loadingMore: false,
      offcanvas: null as any,
      postSlideCanvasIndex: 0,
      postSlideInfo: {
        id: 0,
        userPhoto: "",
        index: 0,
        info: {
          cnName: "黃柔恩",
          collect: false,
          commentCount: 3,
          content: "你好\n你好\n你好\n你好\n你好\n你好\n你好",
          file: null,
          follow: false,
          foreignName: "Irene",
          id: 182,
          images: [
            "https://talcapi-dev.ourscool.com.tw/api/download?s…77652364-a59f693f-6dac-4a42-9e26-31814e3ecfd3.png",
            "https://talcapi-dev.ourscool.com.tw/api/download?s…77670628-79b66b3f-89f4-4794-96c2-ffe2293ca7ae.png",
          ],
          index: 0,
          like: true,
          likeCount: 0,
          photo:
            "https://talcapi-dev.ourscool.com.tw/api/download?section=teaInfo&f=1657010646-9f4bc30c-071d-4726-86eb-c82303a1ee0e.jpg",
          post: [],
          postTime: 1677652365,
          role: "T",
          tags: ["#我好", "#他好"],
          user_id: 47,
        },
      },
      followPostInfo: {
        postId: 0,
        postIndex: 0,
        status: false,
      },
      userPhoto: "",
      userId: 0,
      modalId: "SpecificPostAddToFavoriteClassificationModal",
    };
  },
  emits: ["updateComponent", "updateFollowingPost"],
  mixins: [mixins],
  methods: {
    // obj.info就是要顯示的文字內容
    showToast(obj: { info: string }) {
      this.toastText = obj.info;
      (this as any).$refs.Toast.showToast();
    },
    // 取得用戶頭像
    getUserPhoto() {
      axios
        .get(`${serverUrl}personal`, {
          headers: {
            Authorization: loginToken,
          },
        })
        .then((res) => {
          this.userId = res.data.data.user.id;
          this.userPhoto = res.data.data["user"].photo;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async getMessage(obj: { id: number; index: number; posterId: number }) {
      this.spinnerStatusCount = this.spinnerStatusCount + 1;
      await axios
        .get(
          `${serverUrl}thematic_learning/${
            obj.id
          }/comment?page=${1}&displayedNum=${5}`,
          {
            headers: {
              Authorization: loginToken,
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            for (let i = 0; i < res.data.data.data.length; i++) {
              // 判斷貼文留言跟發文者是不是同一個人
              let messagePosterId = res.data.data.data[i].user_id;
              // console.log(messagePosterId)
              if (messagePosterId === obj.posterId) {
                res.data.data.data[i].isPoster = true;
              } else {
                res.data.data.data[i].isPoster = false;
              }

              // 判斷留言跟登入的用戶是不是同一個人
              if (messagePosterId === this.userId) {
                res.data.data.data[i].isUser = true;
              } else {
                res.data.data.data[i].isUser = false;
              }
              // 重組留言者的名字
              // 老師需要顯示外文名稱及中文名字
              if (res.data.data.data[i].role === "T") {
                res.data.data.data[
                  i
                ].cnName = `${res.data.data.data[i].foreignName}`;
              }
              // 學生如果有暱稱就顯示暱稱，否則才顯示原本的名字
              else if (res.data.data.data[i].role === "S") {
                if (res.data.data.data[i].nickname !== null) {
                  res.data.data.data[i].cnName = res.data.data.data[i].nickname;
                }
              }
              let date = new Date(
                res.data.data.data[i].createdAt.replace(/-/g, "/")
              );
              let time = date.getTime();
              res.data.data.data[i].createdAt = Number(time / 1000);
              for (let j = 0; j < res.data.data.data[i].reply.length; j++) {
                // 判斷留言回覆跟貼文者是不是同一個人
                let replyPosterId = res.data.data.data[i].reply[j].user_id;
                if (replyPosterId === obj.posterId) {
                  res.data.data.data[i].reply[j].isPoster = true;
                } else {
                  res.data.data.data[i].reply[j].isPoster = false;
                }

                if (replyPosterId === this.userId) {
                  res.data.data.data[i].reply[j].isUser = true;
                } else {
                  res.data.data.data[i].reply[j].isUser = false;
                }
                // 重組回覆者的名字
                // 老師需要顯示外文名稱及中文名字
                if (res.data.data.data[i].reply[j].role === "T") {
                  res.data.data.data[i].reply[
                    j
                  ].cnName = `${res.data.data.data[i].reply[j].foreignName}`;
                }
                // 學生如果有暱稱就顯示暱稱，否則才顯示原本的名字
                else if (res.data.data.data[i].reply[j].role === "S") {
                  if (res.data.data.data[i].reply[j].nickname !== null) {
                    res.data.data.data[i].reply[j].cnName =
                      res.data.data.data[i].reply[j].nickname;
                  }
                }
                let date = new Date(
                  res.data.data.data[i].reply[j].createdAt.replace(/-/g, "/")
                );
                let time = date.getTime();
                res.data.data.data[i].reply[j].createdAt = Number(time / 1000);
              }
              // console.log(this.postInfo[obj.index].post);
              // console.log(res.data.data.data[i]);
            }
            // console.log(this.postInfo[obj.index].post);
            this.postInfo[obj.index].post = res.data.data.data;
            if (
              res.data.data.totalPage !== 0 &&
              res.data.data.currentPage !== res.data.data.totalPage
            ) {
              this.postInfo[obj.index].showMore = true;
            } else {
              this.postInfo[obj.index].showMore = false;
            }
            this.spinnerStatusCount = this.spinnerStatusCount - 1;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getMoreMessage(obj: {
      id: number;
      index: number;
      page: number;
      posterId: number;
    }) {
      this.lodaMoreMessage = true;
      axios
        .get(
          `${serverUrl}thematic_learning/${obj.id}/comment?page=${
            obj.page
          }&displayedNum=${5}`,
          {
            headers: {
              Authorization: loginToken,
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            for (let i = 0; i < res.data.data.data.length; i++) {
              let messagePosterId = res.data.data.data[i].user_id;
              // console.log(messagePosterId)
              if (messagePosterId === obj.posterId) {
                res.data.data.data[i].isPoster = true;
              } else {
                res.data.data.data[i].isPoster = false;
              }

              // 判斷留言跟登入的用戶是不是同一個人
              if (messagePosterId === this.userId) {
                res.data.data.data[i].isUser = true;
              } else {
                res.data.data.data[i].isUser = false;
              }
              // 重組留言者的名字
              // 老師需要顯示外文名稱及中文名字
              if (res.data.data.data[i].role === "T") {
                res.data.data.data[
                  i
                ].cnName = `${res.data.data.data[i].foreignName}`;
              }
              // 學生如果有暱稱就顯示暱稱，否則才顯示原本的名字
              else if (res.data.data.data[i].role === "S") {
                if (res.data.data.data[i].nickname !== null) {
                  res.data.data.data[i].cnName = res.data.data.data[i].nickname;
                }
              }
              let date = new Date(
                res.data.data.data[i].createdAt.replace(/-/g, "/")
              );
              let time = date.getTime();
              res.data.data.data[i].createdAt = Number(time / 1000);
              for (let j = 0; j < res.data.data.data[i].reply.length; j++) {
                let replyPosterId = res.data.data.data[i].reply[j].user_id;
                if (replyPosterId === obj.posterId) {
                  res.data.data.data[i].reply[j].isPoster = true;
                } else {
                  res.data.data.data[i].reply[j].isPoster = false;
                }

                if (replyPosterId === this.userId) {
                  res.data.data.data[i].reply[j].isUser = true;
                } else {
                  res.data.data.data[i].reply[j].isUser = false;
                }
                // 重組回覆者的名字
                // 老師需要顯示外文名稱及中文名字
                if (res.data.data.data[i].reply[j].role === "T") {
                  res.data.data.data[i].reply[
                    j
                  ].cnName = `${res.data.data.data[i].reply[j].foreignName}`;
                }
                // 學生如果有暱稱就顯示暱稱，否則才顯示原本的名字
                else if (res.data.data.data[i].reply[j].role === "S") {
                  if (res.data.data.data[i].reply[j].nickname !== null) {
                    res.data.data.data[i].reply[j].cnName =
                      res.data.data.data[i].reply[j].nickname;
                  }
                }
                let date = new Date(
                  res.data.data.data[i].reply[j].createdAt.replace(/-/g, "/")
                );
                let time = date.getTime();
                res.data.data.data[i].reply[j].createdAt = Number(time / 1000);
              }

              this.postInfo[obj.index].post.push(res.data.data.data[i]);
            }

            if (res.data.data.currentPage !== res.data.data.totalPage) {
              this.postInfo[obj.index].showMore = true;
            } else {
              this.postInfo[obj.index].showMore = false;
            }
            this.lodaMoreMessage = false;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    deleteMessage(obj: {
      postIndex: number;
      postId: number;
      messageIndex: number;
      messageId: number;
    }) {
      // 該篇留言對應的回覆數量，刪除留言時會同時刪除底下的回覆，因此要同時計算
      let replyLength =
        this.postInfo[obj.postIndex].post[obj.messageIndex].reply.length;
      this.postInfo[obj.postIndex].post.splice(obj.messageIndex, 1);
      // 更新留言數量
      // 如果該留言有回覆，則一起扣除，否則只留言本身所佔的數量 1
      if (replyLength > 0) {
        this.postInfo[obj.postIndex].commentCount =
          this.postInfo[obj.postIndex].commentCount - 1 - replyLength;
      } else {
        this.postInfo[obj.postIndex].commentCount =
          this.postInfo[obj.postIndex].commentCount - 1;
      }
      this.showToast({ info: "已經刪除留言" });
    },
    deleteReply(obj: {
      postIndex: number;
      postId: number;
      messageIndex: number;
      messageId: number;
      replyId: number;
      replyIndex: number;
    }) {
      this.postInfo[obj.postIndex].post[obj.messageIndex].reply.splice(
        obj.replyIndex,
        1
      );
      // 更新留言數量
      this.postInfo[obj.postIndex].commentCount =
        this.postInfo[obj.postIndex].commentCount - 1;
      this.showToast({ info: "已經刪除回覆" });
    },
    editMessage(
      obj: {
        postIndex: number;
        postId: number;
        messageIndex: number;
        messageId: number;
      },
      message: string
    ) {
      this.postInfo[obj.postIndex].post[obj.messageIndex].content = message;
      this.showToast({ info: "成功編輯留言" });
    },
    editReply(
      obj: {
        postIndex: number;
        postId: number;
        messageIndex: number;
        messageId: number;
        replyId: number;
        replyIndex: number;
      },
      message: string
    ) {
      // this.postInfo[obj.postIndex].post[obj.messageIndex].reply.splice(obj.replyIndex,1);
      this.postInfo[obj.postIndex].post[obj.messageIndex].reply[
        obj.replyIndex
      ].content = message;
      this.showToast({ info: "成功編輯回覆" });
    },
    updatePostLikeStatus(obj: {
      postId: number;
      status: boolean;
      postIndex: number;
      messageIndex?: number;
      replyIndex?: number;
    }) {
      // 透過obj.postIndex可以知道是哪一筆貼文被按讚，同時修改他的按讚狀態，畫面就會隨著更新
      // 當留言及回覆沒有資料，代表是對貼文本身按讚
      if (obj.messageIndex === undefined && obj.replyIndex === undefined) {
        this.postInfo[obj.postIndex].like = !this.postInfo[obj.postIndex].like;
      }
      // 對貼文的留言按讚
      else if (obj.messageIndex !== undefined && obj.replyIndex === undefined) {
        this.postInfo[obj.postIndex].post[obj.messageIndex].like =
          !this.postInfo[obj.postIndex].post[obj.messageIndex].like;
      }
      // 對留言的回覆按讚
      else if (obj.messageIndex !== undefined && obj.replyIndex !== undefined) {
        this.postInfo[obj.postIndex].post[obj.messageIndex].reply[
          obj.replyIndex
        ].like =
          !this.postInfo[obj.postIndex].post[obj.messageIndex].reply[
            obj.replyIndex
          ].like;
      }
      // if (obj.status !== true) {
      //   this.showToast({ info: "你按讚了貼文" });
      // } else {
      //   this.showToast({ info: "你收回按讚" });
      // }
    },
    updatePostMessage(obj: {
      postId: number;
      postIndex: number;
      messageIndex: number;
      message: any;
    }) {
      // 重組回覆者的名字
      // 老師需要顯示外文名稱及中文名字
      if (obj.message.role === "T") {
        obj.message.cnName = `${obj.message.foreignName}`;
      }
      // 學生如果有暱稱就顯示暱稱，否則才顯示原本的名字
      else if (obj.message.role === "S") {
        if (obj.message.nickname !== null) {
          obj.message.cnName = obj.message.nickname;
        }
      }
      // 如果沒有貼文留言的 index ，代表是針對貼文留言
      if (obj.messageIndex === undefined) {
        this.showToast({ info: "成功回覆貼文" });
        // 將該筆留言推入對應的貼文首筆
        obj.message.isUser = true;
        this.postInfo[obj.postIndex].post.unshift(obj.message);
        // 更新留言數量
        this.postInfo[obj.postIndex].commentCount =
          this.postInfo[obj.postIndex].commentCount + 1;
      }
      // 如果有貼文留言的 index ，代表是針對貼文的留言回覆
      else {
        this.showToast({ info: "成功回覆留言" });
        obj.message.isUser = true;
        // console.log(this.postInfo[obj.postIndex].post[obj.messageIndex].reply);
        this.postInfo[obj.postIndex].post[obj.messageIndex].reply.unshift(
          obj.message
        );
        // console.log(this.postInfo[obj.postIndex].post[obj.messageIndex].reply);
        this.postInfo[obj.postIndex].commentCount =
          this.postInfo[obj.postIndex].commentCount + 1;
      }
    },
    updateFollowTeacher(obj: { teacherId: number; status: boolean }) {
      for (let i = 0; i < this.postInfo.length; i++) {
        if (this.postInfo[i].user_id === obj.teacherId) {
          if (obj.status === true) {
            this.postInfo[i].followTeacher = true;
          } else {
            this.postInfo[i].followTeacher = false;
          }
        }
      }
      if (obj.status === true) {
        this.showToast({ info: "已訂閱老師" });
      } else {
        this.showToast({ info: "取消訂閱老師" });
      }
    },
    updateComponent(postId: number) {
      // 這邊的updateKey改為上傳到外面更新收藏貼文
      this.updateKey += 1;
      // 下面這段程式的用意是為了修改前端畫面被按讚或取消的顯示，但舊版的架構是
      // 所有的貼文都在同一筆資料，因此需要從裡面去索引修改
      // 第一層：貼文
      if (this.tercherPostTesting.length > 0) {
        this.tercherPostTesting.forEach((ele: any) => {
          if (ele.postId == postId) {
            ele.postLikesStatus = !ele.postLikesStatus;
          } else {
            // 第二層：貼文留言
            if (ele.post.length > 0) {
              ele.post.forEach((elem: any) => {
                if (elem.replyId == postId) {
                  elem.replyLikeStatus = !elem.replyLikeStatus;
                } else {
                  // 第三層：貼文留言的回覆
                  if (elem.replyOfReply.length > 0) {
                    elem.replyOfReply.forEach((element: any) => {
                      if (element.replyOfReplyId == postId) {
                        element.replyStatus = !element.replyStatus;
                      }
                    });
                  }
                }
              });
            }
          }
        });
      }
    },
    // 當貼文按下加入收藏後，會顯示收藏分類清單
    updateFollowingPost(obj: {
      id: number;
      status: boolean;
      postIndex: number;
    }) {
      // 如果是收藏的要求，顯示收藏分類清單
      if (obj.status === true) {
        this.followPostInfo.postId = obj.id;
        this.followPostInfo.postIndex = obj.postIndex;
        this.followPostInfo.status = obj.status;
        this.addToFavoriteClassification.show();
      }
      // 如果是取消的要求，直接對資料進行處理
      else {
        this.postInfo[obj.postIndex].collect = false;
        this.showToast({ info: "已取消收藏" });
      }
    },
    removeFavoritePost(id: number) {
      for (let i = 0; i < this.postInfo.length; i++) {
        if (this.postInfo[i].id === id) {
          this.postInfo[i].collect = false;
        }
      }
    },
    sendPostId(obj: { id: number; index: number }) {
      // console.log(obj);
      this.postSlideCanvasIndex = obj.index;
      this.getMessage({
        id: obj.id,
        index: obj.index,
        posterId: this.postInfo[obj.index].user_id,
      });
      (this.$refs.postSlideCanvas as any).messageCountInit();
      this.showPostSlidCanvas();
      setTimeout(() => {
        (this.$refs.postSlideCanvas as any).swiperBind();
      }, 200);
    },
    // 處理發文中連結
    linkify(text: string) {
      // eslint-disable-next-line
      var urlRegex =
        /* eslint-disable no-useless-escape */
        /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
      return text.replace(urlRegex, function (url) {
        return (
          '<a class="text-primary-default" href="' + url + '">' + url + "</a>"
        );
      });
    },
    // 顯示回覆 Input
    showReplyButton(targetId: number) {
      const target = document.getElementById(
        `show-reply-comment-${targetId}`
      )! as HTMLElement;
      target.style.display = "flex";
    },
    // 回覆貼文
    leaveComment(targetId: number) {
      const target = document.getElementById(
        `reply-post-${targetId}`
      )! as HTMLInputElement;
      // console.log(targetId);
      // console.log(target);
      const message = target.value;
      if (message.trim() != "") {
        axios
          .post(
            `${serverUrl}thematic_learning`,
            {
              fileName: "",
              content: message,
              replyTo: targetId,
            },
            {
              headers: {
                Authorization: loginToken,
              },
            }
          )
          .then(() => {
            this.$emit("updateComponent");
          })
          .catch((error) => {
            console.error(error);
          });

        target.value = "";
      }
    },
    // 回覆留言
    leaveComment2(commentId: number) {
      const target = document.getElementById(
        `reply-comment-${commentId}`
      )! as HTMLInputElement;
      const message = target.value;

      if (message.trim() != "") {
        axios
          .post(
            `${serverUrl}thematic_learning`,
            {
              fileName: "",
              content: message,
              replyTo: commentId,
            },
            {
              headers: {
                Authorization: loginToken,
              },
            }
          )
          .then(() => {
            this.$emit("updateComponent");
          })
          .catch((error) => {
            console.error(error);
          });

        target.value = "";
      }
    },
    // 追蹤 & 取消追蹤貼文
    followPost(id: number, followStatus: boolean) {
      axios
        .patch(
          `${serverUrl}thematic_learning/${id}/follow`,
          {
            follow: !followStatus,
          },
          {
            headers: {
              Authorization: loginToken,
            },
          }
        )
        .then(() => {
          let newQuery = JSON.parse(JSON.stringify(this.$route.query)); // 砍掉query
          delete newQuery.id;
          this.$router.replace({ query: newQuery });
          this.$emit("updateFollowingPost");
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // 貼文總覽 | 查看單一貼文
    postUpdate(params: PostUpdateParams) {
      this.spinnerStatusCount = this.spinnerStatusCount + 1;
      var url = "";
      if (params.teacherId !== undefined) {
        url = `${serverUrl}thematic_learning/?query=all&page=${params.page}&displayedNum=${params.displayedNum}&teacher=${params.teacherId}&orderByTeacherPin=1`;
      } else if (params.id !== undefined) {
        url = `${serverUrl}thematic_learning/?${params.id}&page=${params.page}&displayedNum=${params.displayedNum}`;
      } else {
        url = `${serverUrl}thematic_learning/?query=${params.category}&page=${params.page}&displayedNum=${params.displayedNum}`;
      }
      axios
        .get(url, {
          headers: {
            Authorization: loginToken,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            // 該貼文已被刪除
            if (res.data.data.data.length === 0) {
              this.$router.push(`/post-deleted`);
              return;
            }
            for (let i = 0; i < res.data.data.data.length; i++) {
              let date = new Date(
                res.data.data.data[i].postTime.replace(/-/g, "/")
              );
              let time = date.getTime();
              // 這邊除 1000 的用意是將時間戳的毫秒轉換成秒
              res.data.data.data[i].postTime = Number(time / 1000);
              res.data.data.data[i].index = i + (this.postPageCount - 1) * 5;

              if (res.data.data.data[i].title !== null) {
                res.data.data.data[
                  i
                ].content = `${res.data.data.data[i].content}`;
              }
              // // 必須預設空陣列，否則貼文卡片內v-for會出現錯誤
              // if (res.data.data.data[i].images === null) {
              //   res.data.data.data[i].images = [];
              // }
              if (this.routePath === "/teachers/teachersintroduction") {
                res.data.data.data[i].pin = res.data.data.data[i].user_pin;
              }
              if (res.data.data.data[i].imagesUrl === null) {
                res.data.data.data[i].images = [];
              } else {
                res.data.data.data[i].images = [
                  res.data.data.data[i].imagesUrl,
                ];
              }
              // res.data.data.data[i].images = [res.data.data.data[i].images];
              res.data.data.data[i].post = [];
              res.data.data.data[i].loadingMoreMessage = 0;
              if (params.id === undefined) {
                this.postInfo.push(res.data.data.data[i]);
              } else {
                this.postInfo.push(res.data.data.data[i]);
                this.postSlideInfo.info = res.data.data.data[i];
              }
            }
            if (this.spinnerStatus) {
              this.spinnerStatus = !this.spinnerStatus;
              this.loadingMore = false;
              if (
                res.data.data.currentPage !== res.data.data.totalPage &&
                res.data.data.total !== 0
              ) {
                window.addEventListener("scroll", this.handleScroll);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    handleScroll() {
      let inner = document.querySelector(".template-container")!;
      if (
        window.scrollY + window.screen.height >= inner.scrollHeight &&
        !this.loadingMore
      ) {
        this.loadingMore = true;
        window.removeEventListener("scroll", this.handleScroll);
      }
    },
    // 呼叫貼文滑入視窗
    showPostSlidCanvas() {
      this.offcanvas.show();
    },
    // 加入收藏，從收藏分類彈出視窗接收到按下加入收藏觸發
    addToFollow(obj: { postIndex: number }) {
      this.postInfo[obj.postIndex].collect = true;
      this.addToFavoriteClassification.hide();
      this.showToast({ info: "成功收藏貼文" });
    },
    copyPostLink() {
      this.showToast({ info: "成功複製貼文連結" });
    },
    newCollectionError() {
      this.showToast({ info: "收藏分類名稱重複" });
    },
    noSelectCollectionCategoryId() {
      this.showToast({ info: "尚未選擇收藏分類" });
    },
    async singlePostGet(id: number) {
      try {
        return await singlePostGet(id);
      } catch (error) {
        console.log(error);
      }
    },
    async showPostModal(id: number) {
      await this.singlePostGet(id).then((res: any) => {
        if (res.data.data.title !== null) {
          res.data.data.content = `<h5 style="margin:0">${res.data.data.title}</h5><br/>${res.data.data.content}`;
        }
        res.data.data.index = 0;
        res.data.data.postTime = Date.parse(res.data.data.postTime) / 1000;
        this.postInfo[this.postSlideCanvasIndex] = res.data.data;
        this.postInfo[this.postSlideCanvasIndex].post = [];
        if (res!.data.data.imagesUrl !== null) {
          this.postInfo[this.postSlideCanvasIndex].images =
            res!.data.data.imagesUrl;
        } else {
          this.postInfo[this.postSlideCanvasIndex].images = [];
        }
      });
      // 取得留言
      this.getMessage({
        id: this.postInfo[this.postSlideCanvasIndex].id,
        index: 0,
        posterId: this.postInfo[this.postSlideCanvasIndex].user_id,
      });
    },
    updateClaps() {
      this.postInfo[0].applauseCount++;
    },
  },
  watch: {
    spinnerStatusCount: function () {
      if (this.spinnerStatusCount > 0) {
        this.spinnerStatus = true;
      } else {
        this.spinnerStatus = false;
      }
    },
  },
  mounted() {
    // 讀取當前 URL ，用以判斷目前在什麼頁面
    const route = useRoute();
    this.routePath = route.path;
    // console.log(`now at ${this.routePath}`)

    this.getUserPhoto();
    // 在動態貼文頁面，取得不分類貼文共 5 筆

    // 在指定貼文頁面， 取得 1 筆文，透過 id 確認是哪一則貼文
    if (this.routePath === "/specific-post") {
      this.postId = Number(this.$route.query.id);
      this.showPostModal(this.postId);
    }

    // Modal 建立
    this.addToFavoriteClassification = new Modal(
      document.getElementById(`${this.modalId}`)! as HTMLElement
    );
  },
  beforeUnmount() {
    this.addToFavoriteClassification = null;
  },
});
