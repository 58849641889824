import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-72c505f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"background-color":"#f6f6f6","position":"relative"} }
const _hoisted_2 = { class: "template-container" }
const _hoisted_3 = { class: "d-flex justify-content-center" }
const _hoisted_4 = { class: "mid-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "mid-column", {}, undefined, true)
        ])
      ])
    ])
  ]))
}